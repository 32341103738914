import {
    Box,
    Button,
    Center,
    Container,
    Flex,
    Image,
    Text,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { FiLogOut } from "react-icons/fi";
import { useFireabseAuth } from "../../../services/hooks/useFirebaseAuth";
import logo from "../../../assets/logo.svg";

interface DefaultLayoutProps {
    children: React.ReactNode | React.ReactNode[];
}

const DefaultLayout: FunctionComponent<DefaultLayoutProps> = ({ children }) => {
    const { logOut } = useFireabseAuth();
    return (
        <Box>
            <Flex
                alignItems={"center"}
                w={"100vw"}
                minH={"5rem"}
                boxShadow={"0px 2px 8px 8px rgba(0, 0, 0, 0.1)"}
                justifyContent={"space-between"}
                px={"5rem"}
                bg={"beige.100"}
            >
                <Image
                    src={logo}
                    borderRadius={"0.5rem"}
                    h={"4.5rem"}
                    filter={"invert(1)"}
                />
                <Button
                    variant={"unstyled"}
                    onClick={() => {
                        logOut();
                    }}
                >
                    <Flex alignItems={"center"} gap={"1rem"}>
                        <Text lineHeight={"1rem"}>Log out</Text>
                        <FiLogOut fontSize={"1.5rem"} />
                    </Flex>
                </Button>
            </Flex>
            <Container maxW={{ base: "85vw", lg: "95vw" }} pb={"5rem"}>
                {children}
            </Container>
        </Box>
    );
};

export default DefaultLayout;
