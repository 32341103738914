import { Box, Center, Spinner, VStack } from "@chakra-ui/react";
import { FunctionComponent, useEffect, useState } from "react";
import Pagination from "../../../../ui/components/Pagination";
import {
    useFetchNewRequests,
    useFetchReviewedReqeusts,
} from "../../api/client";
import RequestsItem from "../RequestItem";
import ReviewedTableHeader from "../ReviewedTableHeader";

interface ReviewedRequestsListProps {}

const ReviewedRequestsList: FunctionComponent<
    ReviewedRequestsListProps
> = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const { data: requestsList, isLoading } =
        useFetchReviewedReqeusts(currentPage);

    return (
        <Box>
            <Box overflowX={"auto"} px={"1rem"} py={"1rem"}>
                <VStack
                    w={"fit-content"}
                    minW={"100rem"}
                    alignItems={"stretch"}
                >
                    <ReviewedTableHeader />
                    {isLoading && (
                        <Center h={"40vh"}>
                            <Spinner />
                        </Center>
                    )}
                    {requestsList?.data.map((e) => {
                        return (
                            <RequestsItem
                                key={e.id}
                                id={e.id}
                                category={e.categoriesList
                                    .map((e: any) => e.category)
                                    .join(", ")}
                                createdAt={
                                    new Date(e.createdAt?._seconds * 1000)
                                }
                                status={e.isReviewed ? "reviewed" : "new"}
                                userName={e.userName}
                                userLocation={e.userLocation}
                                firstName={e.firstName}
                                lastName={e.lastName}
                                email={e.email}
                                readingNumber={e.readingNumber}
                                paid={e.isPaid ? 2.99 : 0}
                                reviewedAt={
                                    new Date(
                                        (e.reviewedAt?._seconds ?? 0) * 1000,
                                    )
                                }
                            />
                        );
                    })}
                </VStack>
            </Box>
            <Center>
                <Pagination
                    currentIndex={currentPage}
                    onChange={setCurrentPage}
                    totalPages={Math.ceil(
                        (requestsList?.paging.totalElements ?? 0) /
                            (requestsList?.paging.limit ?? 1),
                    )}
                />
            </Center>
        </Box>
    );
};

export default ReviewedRequestsList;
