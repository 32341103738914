import { Box, Flex, Heading, SimpleGrid } from "@chakra-ui/react";
import { FunctionComponent } from "react";

interface ReviewedTableHeaderProps {}

const ReviewedTableHeader: FunctionComponent<ReviewedTableHeaderProps> = () => {
    return (
        <Flex
            boxShadow={"0px 0px 4px 4px rgba(0, 0, 0, 0.05)"}
            borderRadius={"0.5rem"}
            py={"1rem"}
            px={"1.5rem"}
            h={"4.5rem"}
            position={"relative"}
            opacity={status === "reviewed" ? 0.5 : 1}
            fontWeight={600}
            alignItems={"center"}
        >
            <Heading
                fontSize={"inherit"}
                fontWeight={"inherit"}
                fontFamily={"inherit"}
                minW={"10rem"}
            >
                Username
            </Heading>

            <Heading
                fontSize={"inherit"}
                fontWeight={"inherit"}
                fontFamily={"inherit"}
                minW={"6rem"}
            >
                Status
            </Heading>

            <Heading
                fontSize={"inherit"}
                fontWeight={"inherit"}
                fontFamily={"inherit"}
                minW={"8rem"}
            >
                Date
            </Heading>

            <Heading
                fontSize={"inherit"}
                fontWeight={"inherit"}
                fontFamily={"inherit"}
                minW={"6rem"}
            >
                Time
            </Heading>

            <Heading
                fontSize={"inherit"}
                fontWeight={"inherit"}
                fontFamily={"inherit"}
                minW={"12rem"}
            >
                Location/timezone
            </Heading>
            <Heading
                fontSize={"inherit"}
                fontWeight={"inherit"}
                fontFamily={"inherit"}
                minW={"8rem"}
            >
                First Name
            </Heading>
            <Heading
                fontSize={"inherit"}
                fontWeight={"inherit"}
                fontFamily={"inherit"}
                minW={"8rem"}
            >
                Second Name
            </Heading>
            <Heading
                fontSize={"inherit"}
                fontWeight={"inherit"}
                fontFamily={"inherit"}
                minW={"15rem"}
            >
                Email
            </Heading>
            <Heading
                fontSize={"inherit"}
                fontWeight={"inherit"}
                fontFamily={"inherit"}
                minW={"9rem"}
            >
                Reading Number
            </Heading>
            <Heading
                fontSize={"inherit"}
                fontWeight={"inherit"}
                fontFamily={"inherit"}
                minW={"6rem"}
            >
                Free/Paid
            </Heading>
            <Heading
                fontSize={"inherit"}
                fontWeight={"inherit"}
                fontFamily={"inherit"}
                minW={"10rem"}
            >
                Status
            </Heading>
            <Heading
                fontSize={"inherit"}
                fontWeight={"inherit"}
                fontFamily={"inherit"}
                minW={"8rem"}
            >
                Response Time
            </Heading>
            <Box minW={"8rem"}></Box>
        </Flex>
    );
};

export default ReviewedTableHeader;
