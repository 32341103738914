import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Image, ImageProps } from "@chakra-ui/react";
import heic2any from "heic2any";
import { getBlob, getDownloadURL, getStorage, ref } from "firebase/storage";

interface HeicableImageProps {
    imageProps?: ImageProps;
    src: string;
}

const HeicableImage: FunctionComponent<HeicableImageProps> = ({
    imageProps,
    src,
}) => {
    const [convertedSrc, setConvertedSrc] = useState<string>(src);

    useEffect(() => {
        const callback = async () => {
            if (src.toLowerCase().includes("heic")) {
                let blob;

                try {
                    const response = await fetch(src);
                    blob = await response.blob();
                } catch (error) {
                    const storage = getStorage();
                    try {
                        const fileRef = ref(storage, src);
                        blob = await getBlob(fileRef);
                    } catch (error) {
                        //TODO: add error handling
                        setConvertedSrc(src);
                    }
                }

                if (blob) {
                    setConvertedSrc(
                        URL.createObjectURL(
                            (await heic2any({ blob, toType: "png" })) as Blob,
                        ),
                    );
                }
            } else {
                setConvertedSrc(src);
            }
        };

        callback();
    }, [src]);

    return <Image {...imageProps} src={convertedSrc} />;
};

export default HeicableImage;
