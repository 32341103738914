import {
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Button,
} from "@chakra-ui/react";
import { FunctionComponent, useRef } from "react";

interface ConfirmCloseWithoutSavingProps {
    isOpen: boolean;
    onClose(): void;
    onAccept(): void;
}

const ConfirmCloseWithoutSaving: FunctionComponent<
    ConfirmCloseWithoutSavingProps
> = ({ isOpen, onClose, onAccept }) => {
    const cancelRef = useRef<any>();
    return (
        <AlertDialog
            leastDestructiveRef={cancelRef}
            isOpen={isOpen}
            onClose={onClose}
            isCentered
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Close prediction
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        If you close this window now, your data will be lost
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button onClick={onClose}>Go back to editing</Button>
                        <Button colorScheme="red" onClick={onAccept} ml={3}>
                            Close
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default ConfirmCloseWithoutSaving;
