import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const base = defineStyle({
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "xl",
});

const light = defineStyle({
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "xl",
    color: "beige.100",
});

const dark = defineStyle({
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "xl",
    color: "black.900",
});

export const headingTheme = defineStyleConfig({
    defaultProps: {
        size: "xl",
        variant: "base",
    },

    variants: {
        base,
        dark,
        light,
    },
});
