import { Box, Heading, VStack } from "@chakra-ui/react";
import { FunctionComponent, useEffect } from "react";

import RequestsList from "../../features/requests/components/RequestsList";
import { useUserStore } from "../../services/store/userStore";

interface MainPageProps {}

const MainPage: FunctionComponent<MainPageProps> = () => {
    return (
        <VStack pt={"15vh"} alignItems={"flex-start"}>
            <Heading
                fontSize={"4em"}
                fontWeight={"400"}
                fontFamily={"Raleway"}
                mb={"0.5rem"}
            >
                Hello there, <b>Admin</b>
            </Heading>
            <Heading fontSize={"1.5rem"} fontWeight={"400"} mb={"2rem"}>
                Ready to tell people their fortune?
            </Heading>

            <RequestsList />
        </VStack>
    );
};

export default MainPage;
