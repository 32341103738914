import axios, {
    AxiosError,
    AxiosHeaders,
    AxiosRequestConfig,
    AxiosRequestHeaders,
} from "axios";
import { useFireabseAuth } from "../hooks/useFirebaseAuth";
import { useUserStore } from "../store/userStore";

export const APIClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

APIClient.interceptors.request.use((config) => {
    const { accessToken } = useUserStore.getState();

    return {
        ...config,
        headers: {
            authorization: `Bearer ${accessToken}`,
        },
    };
});

APIClient.interceptors.response.use(
    ({ data }) => data,
    (error: AxiosError) => Promise.reject(error),
);
