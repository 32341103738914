// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC04PCXxxLgPHTXpsdF07xfVnUjV0RNcyQ",
    authDomain: "teatellerdev-bdb0d.firebaseapp.com",
    projectId: "teatellerdev-bdb0d",
    storageBucket: "teatellerdev-bdb0d.appspot.com",
    messagingSenderId: "436488800505",
    appId: "1:436488800505:web:3dbd49528992b6d87e3a28",
    measurementId: "G-Z15KVXLHNK",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
