import { useQuery } from "@tanstack/react-query";
import { APIClient } from "../../../services/API/apiClient";
import { SingleUserDataType } from "./types";

export const useFetchUserById = (id?: string) => {
    return useQuery(
        ["user", id],
        () => {
            return APIClient.get<unknown, SingleUserDataType>("users/" + id);
        },
        {
            enabled: !!id,
        },
    );
};
