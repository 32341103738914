import { extendTheme } from "@chakra-ui/react";
import { headingTheme } from "./headingTheme";

export const theme = extendTheme({
    styles: {
        global: {
            body: {
                bg: "beige.100",
                fontFamily: "Raleway, sans-serif",
                color: "black.900",
            },
        },
    },
    colors: {
        black: {
            900: "#111010", // Base color
            800: "#2B2A2A",
            700: "#454545",
            600: "#5E5D5D",
            500: "#787777",
            400: "#919090",
            300: "#ABAAAA",
            200: "#C4C3C3",
            100: "#DEDDDD",
        },
        coffee: {
            100: "#F4CDBE", // Lighter
            200: "#F0C2A8",
            300: "#ECA793",
            400: "#E89C7D",
            500: "#E3A97D", // Base Color
            600: "#C37E59",
            700: "#A35436",
            800: "#843A13",
            900: "#641000", // Darker
        },
        beige: {
            100: "#F5EADA", // Base Color
            200: "#E8D1C2",
            300: "#DBB8AA",
            400: "#CE9F92",
            500: "#C1867A",
            600: "#A36C62",
            700: "#86534A",
            800: "#693A32",
            900: "#4C211A",
        },
    },
    components: {
        Heading: headingTheme,
    },
});
