import { Center, Spinner } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";
import { useIsAdmin } from "../../features/requests/api/client";
import { useFireabseAuth } from "../hooks/useFirebaseAuth";

interface ProtectedRouteProps {
    redirectPath?: string;
    children: React.ReactNode | React.ReactNode[];
}

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({
    redirectPath = "/login",
    children,
}) => {
    const { user, isLoading } = useFireabseAuth();
    const { data: isAdmin, isFetching, isRefetching } = useIsAdmin();

    if (isLoading || isFetching || isRefetching) {
        return (
            <Center minH={"100vh"} gap={"1rem"}>
                Loading <Spinner />
            </Center>
        );
    }

    if (!user || !isAdmin) {
        return <Navigate to={redirectPath} replace />;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
