import {
    Flex,
    Stack,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    Text,
    Box,
    VStack,
    InputGroup,
    FormErrorMessage,
} from "@chakra-ui/react";
import { useFormik } from "formik";

import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginValidationSchema } from "../../features/login/api/validationSchema";
import { useIsAdmin } from "../../features/requests/api/client";
import { useFireabseAuth } from "../../services/hooks/useFirebaseAuth";
import { useUserStore } from "../../services/store/userStore";

interface LoginProps {}

const Login: FunctionComponent<LoginProps> = () => {
    const { logIn, user, logOut, isLoading } = useFireabseAuth();
    const { accessToken } = useUserStore();
    const { refetch: refetchIsAdmin, isRefetching } = useIsAdmin();
    const navigate = useNavigate();

    useEffect(() => {
        if (accessToken) {
            refetchIsAdmin().then((updatedStatus) => {
                if (updatedStatus.data) {
                    navigate("/");
                } else {
                    toast.error("You are not authorized to view this page");
                    logOut();
                }
            });
        }
        console.log("Спеціально для Васильовича", accessToken);
    }, [accessToken]);

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: loginValidationSchema,
        validateOnChange: false,
        validateOnMount: false,
        onSubmit: (values) => {
            logIn(values.email, values.password);
        },
    });

    return (
        <Flex minH={"100vh"} align={"center"} justify={"center"}>
            <VStack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
                <Stack align={"center"}>
                    <Heading fontSize={"4xl"}>Hello there, Admin!</Heading>
                    <Text fontSize={"lg"}></Text>
                </Stack>
                <Box
                    borderRadius={"2rem"}
                    bg={"beige.200"}
                    boxShadow={"lg"}
                    px={"2rem !important"}
                    py={"3em !important"}
                    pb={"1rem"}
                    p={8}
                    minW={"25rem"}
                >
                    <Stack spacing={4} color={"beige.100"}>
                        <FormControl
                            id="email"
                            isInvalid={!!formik.errors.email}
                        >
                            <FormLabel
                                fontWeight={"400"}
                                fontSize={"1.25rem"}
                                color={"black.900"}
                            >
                                Email address
                            </FormLabel>

                            <Input
                                id={"email"}
                                name={"email"}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                type="email"
                                bg={"beige.100"}
                                color={"black.900"}
                                borderColor={"transparent !important"}
                            />

                            <FormErrorMessage h={"0"}>
                                {formik.errors.email}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl
                            id="password"
                            isInvalid={!!formik.errors.password}
                        >
                            <FormLabel
                                fontWeight={"400"}
                                fontSize={"1.25rem"}
                                color={"black.900"}
                            >
                                Password
                            </FormLabel>
                            <Input
                                id={"password"}
                                value={formik.values.password}
                                name={"password"}
                                onChange={formik.handleChange}
                                type="password"
                                bg={"beige.100"}
                                color={"black.900"}
                                borderColor={"transparent !important"}
                            />
                            <FormErrorMessage h={0}>
                                {formik.errors.password}
                            </FormErrorMessage>
                        </FormControl>
                        <Flex w={"100%"} justifyContent={"flex-end"}>
                            <Button
                                bg={"coffee.500"}
                                h={"3rem"}
                                px={"2rem"}
                                fontWeight={"800"}
                                color={"white"}
                                _hover={{
                                    transform: "scale(1.03)",
                                }}
                                onClick={() => {
                                    formik.handleSubmit();
                                }}
                                isLoading={isLoading || isRefetching}
                            >
                                Sign in
                            </Button>
                        </Flex>
                    </Stack>
                </Box>
            </VStack>
        </Flex>
    );
};

export default Login;
