import { User } from "firebase/auth";
import { create } from "zustand";

interface UseUserStoreType {
    user?: User;
    accessToken: string;
    setUser: (newUser: User) => void;
    setAccessToken: (token: string) => void;
    clearUser: () => void;
}

export const useUserStore = create<UseUserStoreType>((set) => ({
    user: undefined,
    accessToken: "",
    setAccessToken: (token: string) => set({ accessToken: token }),
    setUser: (newUser: User) => set(() => ({ user: newUser })),
    clearUser: () => set({ user: undefined }),
}));
