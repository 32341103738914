import { app } from "../firebase/firebaseConfig";
import { getAuth, signInWithEmailAndPassword, User } from "firebase/auth";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useUserStore } from "../store/userStore";

export const useFireabseAuth = () => {
    const auth = getAuth(app);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isTokenLoading, setIsTokenLoading] = useState<boolean>(false);

    const { user, setAccessToken, setUser, clearUser } = useUserStore();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((data) => {
            if (data) {
                setIsLoading(true);
                setUser(data);
                data.getIdToken().then((token) => {
                    setAccessToken(token);
                    setIsLoading(false);
                });
            } else {
                clearUser();
                setAccessToken("");
                setIsLoading(false);
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const logIn = async (email: string, password: string) => {
        setIsLoading(true);
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (error: any) {
            if (error.code === "auth/wrong-password") {
                toast.error("Log in failed: Wrong password");
            } else if (error.code === "auth/user-not-found") {
                toast.error(
                    "Log in failed: No user with specified email found",
                );
            }

            setIsLoading(false);
        }
    };

    const logOut = () => {
        auth.signOut();
        clearUser();
        setAccessToken("");
    };

    return {
        user: user,
        logIn,
        logOut,
        isLoading,
    };
};
