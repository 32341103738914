import { FunctionComponent } from "react";
import { MdClose } from "react-icons/md";
import { Carousel } from "react-responsive-carousel";

import {
    Box,
    Button,
    Center,
    IconButton,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/react";

import classes from "./styles.module.scss";

interface MediaViewerProps {
    isOpen: boolean;
    onClose(): void;
    media?: string[];
    currentIndex?: number;
}

const MediaViewer: FunctionComponent<MediaViewerProps> = ({
    isOpen,
    onClose,
    media,
    currentIndex = 0,
}) => {
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size={"full"} isCentered>
                <ModalOverlay />
                <IconButton
                    position={"absolute"}
                    top={"2%"}
                    right={"3%"}
                    color={"white"}
                    icon={<MdClose fontSize={"3em"} />}
                    aria-label={""}
                    bg={"transparent"}
                    onClick={onClose}
                    zIndex={1000000}
                />
                <ModalContent bg={"transparent"}>
                    <Center zIndex={100000}>
                        <Carousel
                            width={"100%"}
                            className={classes.carousel}
                            showArrows={true}
                            showStatus={false}
                            selectedItem={currentIndex}
                        >
                            {media?.map((e) => {
                                return (
                                    <Center
                                        h={"100vh"}
                                        key={e}
                                        onClick={onClose}
                                    >
                                        <Image
                                            src={e}
                                            maxH={"80%"}
                                            w={"auto !important"}
                                        />
                                    </Center>
                                );
                            })}
                        </Carousel>
                    </Center>
                </ModalContent>
            </Modal>
        </>
    );
};

export default MediaViewer;
