import { Center, VStack, Heading, Input } from "@chakra-ui/react";
import heic2any from "heic2any";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { IoIosAddCircleOutline } from "react-icons/io";

interface RequestUploadImageBoxProps {
    onChange(file: File): void;
}

const RequestUploadImageBox: FunctionComponent<RequestUploadImageBoxProps> = ({
    onChange,
}) => {
    const [file, setFile] = useState<File>();
    const inputRef = useRef<HTMLInputElement>(null);

    const handleUploadClick = () => {
        inputRef.current?.click();
    };

    useEffect(() => {
        if (file) onChange(file);
    }, [file]);

    return (
        <>
            <Center
                borderRadius={"0.5rem"}
                bg={"gray.100"}
                cursor={"pointer"}
                _hover={{
                    transform: "scale(1.02)",
                }}
                transition={"all 0.3s ease-in"}
                onClick={() => {
                    handleUploadClick();
                }}
                zIndex={"100"}
            >
                <VStack opacity={"0.5"}>
                    <IoIosAddCircleOutline fontSize={"4rem"} />
                    <Heading
                        fontWeight={400}
                        fontSize={"1rem"}
                        textAlign={"center"}
                    >
                        Click here to add your prediction image
                    </Heading>
                </VStack>
            </Center>
            <Input
                ref={inputRef}
                type={"file"}
                display={"none"}
                id={"predictionUploader"}
                name={"predictionUploader"}
                accept="image/*, .heic"
                style={{ visibility: "hidden" }}
                onChange={async (e) => {
                    if (
                        e?.target?.files?.[0] &&
                        e.target.files[0].name.includes(".HEIC")
                    ) {
                        const blobURL = URL.createObjectURL(
                            e?.target?.files?.[0],
                        );

                        // convert "fetch" the new blob url
                        const blobRes = await fetch(blobURL);

                        // convert response to blob
                        const blob = await blobRes.blob();

                        // convert to PNG - response is blob
                        const conversionResult = await heic2any({ blob });
                        setFile(
                            new File(
                                [conversionResult] as BlobPart[],
                                "heic_image",
                            ),
                        );
                    } else {
                        setFile(e?.target?.files?.[0]);
                    }
                }}
            />
        </>
    );
};

export default RequestUploadImageBox;
