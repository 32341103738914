import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Flex,
    Avatar,
    Text,
    VStack,
    Heading,
    SimpleGrid,
    Image,
    Center,
    Textarea,
    Box,
    useDisclosure,
    Tooltip,
    Spinner,
} from "@chakra-ui/react";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useFetchUserById } from "../../../users/api/client";
import { useFetchRequestById, useReviewRequest } from "../../api/client";
import { IoIosAddCircleOutline } from "react-icons/io";
import RequestUploadImageBox from "../RequestUploadImageBox";
import ImageViewer from "react-simple-image-viewer";
import MediaViewer from "../../../../ui/components/ImageViewer";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useUploadFirebaseImage } from "../../../../services/hooks/useUploadFirebaseImage";
import ConfirmCloseWithoutSaving from "../ConfirmCloseWithoutSaving";
import { toast } from "react-toastify";
import HeicableImage from "../../../../ui/components/HeicableImage";

interface RequestsModalProps {
    isOpen: boolean;
    onClose(): void;
    requestId: string;
}

const RequestsModal: FunctionComponent<RequestsModalProps> = ({
    isOpen,
    onClose,
    requestId,
}) => {
    const { data: requestData, isLoading: isFetchingRequest } =
        useFetchRequestById(requestId);
    const { mutate: reviewRequest, isLoading } = useReviewRequest(requestId);
    const { upload, isLoading: isImageUploading } = useUploadFirebaseImage();
    const {
        isOpen: isConfirmOpen,
        onOpen: onConfirmOpen,
        onClose: onConfirmClose,
    } = useDisclosure();

    const { data: userData } = useFetchUserById(requestData?.userId);
    const [predictionImage, setPredictionImage] = useState<File>();
    const localPredictionImageUrl = useMemo(() => {
        if (predictionImage) return URL.createObjectURL(predictionImage);
        return undefined;
    }, [predictionImage]);
    const [viewerImageIndex, setViewerImageIndex] = useState<number>(-1);

    const [description, setDescription] = useState<string>("");
    const [shortSummary, setShortSummary] = useState<string>("");

    useEffect(() => {
        if (requestData) {
            setDescription(requestData.description);
            setShortSummary(requestData.summary);
        }
    }, [requestData]);

    const sendPredictionFunction = async () => {
        if (predictionImage) {
            try {
                const imgUrl = await upload(predictionImage);

                reviewRequest(
                    {
                        description,
                        shortSummary,
                        predictionImageUrl: imgUrl,
                    },
                    {
                        onSettled: () => {
                            onClose();
                            toast.success("Request successfully reviewed");
                        },
                    },
                );
            } catch (error) {
                console.log(
                    "🚀 ~ file: index.tsx:78 ~ sendPredictionFunction ~ error:",
                    error,
                );
                // eslint-disable-next-line no-debugger
                debugger;
            }
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            motionPreset="slideInBottom"
        >
            <ModalOverlay
                bg="none"
                backdropFilter="auto"
                backdropInvert="100%"
                backdropBlur="2px"
            />
            <ModalContent maxW={"80vw"}>
                <ModalCloseButton />
                <ModalBody pt={"3rem"}>
                    {!isFetchingRequest && (
                        <>
                            <SimpleGrid columns={4} gap={"0.75rem"} mb={"1rem"}>
                                {Array.isArray(requestData?.imageUrls) &&
                                    requestData?.imageUrls.map(
                                        (imageUrl, index) => {
                                            return (
                                                <Image
                                                    key={imageUrl}
                                                    src={imageUrl}
                                                    fallbackSrc="https://www.webfx.com/wp-content/uploads/2021/10/generic-image-placeholder.png"
                                                    h={"45vh"}
                                                    w={"100%"}
                                                    borderRadius={"0.5rem"}
                                                    objectFit={"cover"}
                                                    cursor={"pointer"}
                                                    onClick={() => {
                                                        setViewerImageIndex(
                                                            index,
                                                        );
                                                    }}
                                                />
                                            );
                                        },
                                    )}

                                {!requestData?.predictionImageUrl &&
                                    !predictionImage && (
                                        <RequestUploadImageBox
                                            onChange={(image) => {
                                                setPredictionImage(image);
                                            }}
                                        />
                                    )}

                                {(predictionImage ||
                                    requestData?.predictionImageUrl) && (
                                    <Box position={"relative"}>
                                        <Center
                                            position={"absolute"}
                                            color={"white"}
                                            bg={"#305202"}
                                            fontSize={"0.85rem"}
                                            top={0}
                                            right={0}
                                            px={"1rem"}
                                            py={"0.125rem"}
                                            borderRadius={"0 0.5rem 0 0.5rem"}
                                            fontWeight={800}
                                        >
                                            Prediction image
                                        </Center>

                                        <HeicableImage
                                            src={
                                                localPredictionImageUrl ??
                                                requestData?.predictionImageUrl ??
                                                ""
                                            }
                                            imageProps={{
                                                h: "45vh",
                                                w: "100%",

                                                borderRadius: "0.5rem",
                                                objectFit: "cover",
                                                boxShadow:
                                                    "0px 0px 8px 8px #30520240",
                                                onClick: () => {
                                                    setViewerImageIndex(
                                                        requestData?.imageUrls
                                                            .length ?? 0,
                                                    );
                                                },
                                            }}
                                        />
                                        <Image />
                                    </Box>
                                )}
                            </SimpleGrid>

                            <Flex
                                justifyContent={"space-between"}
                                alignItems={"center"}
                            >
                                <Flex gap={"0.5rem"}>
                                    <Avatar
                                        boxShadow={
                                            "0px 0px 4px 4px rgba(0, 0, 0, 0.1)"
                                        }
                                        src={userData?.photoUrl}
                                        name={
                                            userData?.firstName +
                                            " " +
                                            userData?.lastName
                                        }
                                    />
                                    <VStack
                                        alignItems={"flex-start"}
                                        spacing={0}
                                        gap={0}
                                    >
                                        <Text
                                            color={"black.900"}
                                            fontWeight={"500"}
                                        >
                                            {userData?.firstName +
                                                " " +
                                                userData?.lastName}
                                        </Text>
                                        <Text
                                            color={"black.900"}
                                            fontWeight={"600"}
                                            fontSize={"0.8rem"}
                                            opacity={"0.5"}
                                        >
                                            {userData?.email}
                                        </Text>
                                    </VStack>
                                </Flex>

                                <VStack alignItems={"flex-start"}>
                                    {requestData?.categoriesList.map((e) => {
                                        return (
                                            <Heading
                                                key={e.category}
                                                fontSize={"0.8rem"}
                                                fontWeight={"500"}
                                            >
                                                {e.category}:{" "}
                                                <b>
                                                    {e.subCategories.join(", ")}
                                                </b>
                                            </Heading>
                                        );
                                    })}
                                </VStack>
                                <Text
                                    fontSize={"1rem"}
                                    fontWeight={"400"}
                                    mr={"1.5rem"}
                                >
                                    {new Date(
                                        (requestData?.createdAt?._seconds ??
                                            0) * 1000,
                                    ).toLocaleDateString("en-GB")}
                                </Text>
                            </Flex>
                            <SimpleGrid
                                gridTemplateColumns={"3fr 1fr"}
                                gap={"1rem"}
                            >
                                <Box>
                                    <Heading mt={"1rem"} fontWeight={"400"}>
                                        Description:
                                    </Heading>
                                    <Textarea
                                        readOnly={requestData?.isReviewed}
                                        resize={"vertical"}
                                        value={description}
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                        }}
                                        fontSize={"1.25rem"}
                                        h={"12rem"}
                                    />
                                </Box>
                                <Box>
                                    <Heading mt={"1rem"} fontWeight={"400"}>
                                        Short summary:
                                    </Heading>
                                    <Textarea
                                        readOnly={requestData?.isReviewed}
                                        resize={"vertical"}
                                        value={shortSummary}
                                        onChange={(e) => {
                                            setShortSummary(e.target.value);
                                        }}
                                        h={"12rem"}
                                        maxLength={200}
                                    />
                                    <Text w={"100%"} textAlign={"end"}>
                                        {shortSummary.length}/200
                                    </Text>
                                </Box>
                            </SimpleGrid>
                        </>
                    )}

                    {isFetchingRequest && (
                        <Center minH={"50vh"}>
                            <Spinner size="xl" />
                        </Center>
                    )}
                </ModalBody>

                <ModalFooter>
                    <Button
                        variant="ghost"
                        onClick={() => {
                            if (requestData?.isReviewed) {
                                onClose();
                            }

                            if (
                                description === "" &&
                                shortSummary === "" &&
                                !predictionImage
                            ) {
                                onClose();
                            } else {
                                onConfirmOpen();
                            }
                        }}
                    >
                        Close
                    </Button>
                    {!requestData?.isReviewed && (
                        <Tooltip
                            display={
                                shortSummary === "" ||
                                description === "" ||
                                !predictionImage
                                    ? "block"
                                    : "none"
                            }
                            label={
                                "Fill out the following fields to send prediction: " +
                                (shortSummary === ""
                                    ? "short summary, "
                                    : "" + description === ""
                                    ? "description, "
                                    : "" + !predictionImage
                                    ? "prediction image"
                                    : "")
                            }
                        >
                            <Button
                                _hover={{ bg: "black.700" }}
                                bg="black.900"
                                mr={3}
                                color={"white"}
                                isDisabled={
                                    shortSummary === "" ||
                                    description === "" ||
                                    !predictionImage
                                }
                                onClick={sendPredictionFunction}
                                isLoading={isLoading || isImageUploading}
                            >
                                Send prediction
                            </Button>
                        </Tooltip>
                    )}
                </ModalFooter>
            </ModalContent>

            <MediaViewer
                media={
                    localPredictionImageUrl || requestData?.predictionImageUrl
                        ? [
                              ...(requestData?.imageUrls ?? []),

                              localPredictionImageUrl ??
                                  requestData?.predictionImageUrl ??
                                  "",
                          ]
                        : [...(requestData?.imageUrls ?? [])]
                }
                onClose={() => {
                    setViewerImageIndex(-1);
                }}
                currentIndex={viewerImageIndex}
                isOpen={viewerImageIndex >= 0}
            />

            <ConfirmCloseWithoutSaving
                isOpen={isConfirmOpen}
                onClose={onConfirmClose}
                onAccept={() => {
                    onConfirmClose();
                    onClose();
                }}
            />
        </Modal>
    );
};

export default RequestsModal;
