import {
    Box,
    Button,
    Center,
    Flex,
    Heading,
    SimpleGrid,
    useDisclosure,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { BsChevronRight, BsCupHot } from "react-icons/bs";
import { timeSince } from "../../../../services/utils/dateFn";
import RequestsModal from "../RequestsModal";

interface RequestsItemProps {
    id: string;
    status: "reviewed" | "new";
    createdAt: Date;
    category: string;
    userName: string;
    firstName: string;
    lastName: string;
    userLocation: string;
    email: string;
    readingNumber: number;
    paid?: number;
    reviewedAt: Date;
}

const RequestsItem: FunctionComponent<RequestsItemProps> = ({
    id,
    status,
    createdAt,
    category,
    userName = "Anonymous user",
    userLocation,
    firstName,
    lastName,
    email,
    readingNumber,
    paid = 0,
    reviewedAt,
}) => {
    const {
        isOpen: isModalOpen,
        onOpen: onModalOpen,
        onClose: onModalClose,
    } = useDisclosure();

    return (
        <Flex
            boxShadow={"0px 0px 4px 4px rgba(0, 0, 0, 0.05)"}
            borderRadius={"0.5rem"}
            py={"1rem"}
            px={"1.5rem"}
            h={"4.5rem"}
            position={"relative"}
            opacity={status === "reviewed" ? 0.5 : 1}
        >
            {Date.now() / 1000 - createdAt.getTime() < 48 * 60 * 60 &&
                status === "new" && (
                    <Box
                        position={"absolute"}
                        zIndex={2}
                        top={"0"}
                        right={0}
                        borderRadius={"0 0.5rem 0 0.5rem"}
                        px={"1rem"}
                        py="0.125rem"
                        bg={"coffee.500"}
                        opacity={"0.9"}
                        color="white"
                        fontWeight={"800"}
                        fontSize={"1rem"}
                    >
                        new
                    </Box>
                )}

            <Heading fontWeight={400} fontSize={"1rem"} minW={"10rem"}>
                {userName}
            </Heading>

            <Heading fontWeight={400} fontSize={"1rem"} minW={"6rem"}>
                {status.toLocaleUpperCase()}
            </Heading>

            <Heading fontWeight={400} fontSize={"1rem"} minW={"8rem"}>
                {createdAt.toLocaleDateString("en-GB")}
            </Heading>

            <Heading fontWeight={400} fontSize={"1rem"} minW={"6rem"}>
                {createdAt.toLocaleTimeString("en-GB", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                })}
            </Heading>

            <Heading fontWeight={400} fontSize={"1rem"} minW={"12rem"}>
                {userLocation}
            </Heading>

            <Heading fontWeight={400} fontSize={"1rem"} w={"8rem"}>
                {firstName}
            </Heading>
            <Heading fontWeight={400} fontSize={"1rem"} w={"8rem"}>
                {lastName}
            </Heading>

            <Heading fontWeight={600} fontSize={"1rem"} minW={"15rem"}>
                {email}
            </Heading>

            <Heading fontWeight={400} fontSize={"1rem"} minW={"9rem"}>
                {readingNumber}
            </Heading>

            <Heading fontWeight={400} fontSize={"1rem"} minW={"6rem"}>
                {paid}
            </Heading>

            <Heading
                fontWeight={400}
                fontSize={"1rem"}
                minW={"10rem"}
                color={status === "new" ? "red.600" : "green"}
            >
                {status === "new" ? "Unread" : "Complete"}
            </Heading>

            <Heading fontWeight={400} fontSize={"1rem"} minW={"8rem"}>
                {status === "new"
                    ? timeSince(createdAt)
                    : reviewedAt.toLocaleTimeString("en-GB", {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                      })}
            </Heading>

            <Center minW={"8rem"}>
                {status === "new" && (
                    <Button
                        justifySelf={"flex-end"}
                        marginLeft={"auto"}
                        rightIcon={<BsCupHot />}
                        bg={"rgba(28, 69, 27, 0.75)"}
                        color="white"
                        onClick={onModalOpen}
                    >
                        Make prediction
                    </Button>
                )}

                {status === "reviewed" && (
                    <Button
                        justifySelf={"flex-end"}
                        marginLeft={"auto"}
                        rightIcon={<BsChevronRight />}
                        onClick={onModalOpen}
                    >
                        Review request
                    </Button>
                )}
            </Center>

            {isModalOpen && (
                <RequestsModal
                    isOpen={isModalOpen}
                    onClose={onModalClose}
                    requestId={id}
                />
            )}
        </Flex>
    );
};

export default RequestsItem;
