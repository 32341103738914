import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { APIClient } from "../../../services/API/apiClient";
import { useUserStore } from "../../../services/store/userStore";
import {
    RequestListItemResponseType,
    RequestsListResponseType,
    SpecifiedRequestsListResponseType,
} from "./types";

export const useFetchAllRequests = () => {
    return useQuery(["requestsList"], () => {
        return APIClient.get<unknown, RequestsListResponseType>("requests/all");
    });
};

export const useFetchNewRequests = (page: number) => {
    return useQuery(["newRequestsList", page], () => {
        return APIClient.get<unknown, SpecifiedRequestsListResponseType>(
            "requests/not-reviewed?page=" + page + "&limit=10",
        );
    });
};

export const useFetchReviewedReqeusts = (page: number) => {
    return useQuery(["reviewedRequestsList", page], () => {
        return APIClient.get<unknown, SpecifiedRequestsListResponseType>(
            "requests/reviewed?page=" + page + "&limit=10",
        );
    });
};

export const useFetchRequestById = (id: string, enabled: boolean = true) => {
    return useQuery(
        ["requestData", id],
        () => {
            return APIClient.get<unknown, RequestListItemResponseType>(
                "requests/" + id,
            );
        },
        { enabled: enabled },
    );
};

export const useReviewRequest = (id: string) => {
    const client = useQueryClient();

    return useMutation(
        ({
            description,
            shortSummary,
            predictionImageUrl,
        }: {
            description: string;
            shortSummary: string;
            predictionImageUrl: string;
        }) => {
            return APIClient.patch("/requests/" + id, {
                description,
                summary: shortSummary,
                predictionImageUrl,
                isReviewed: true,
            });
        },
        {
            onSettled: () => {
                client.invalidateQueries(["requestsList"]);
                client.invalidateQueries(["requestData", id]);
                client.invalidateQueries(["newRequestsList"]);
                client.invalidateQueries(["reviewedRequestsList"]);
            },
        },
    );
};

export const useIsAdmin = () => {
    const { accessToken } = useUserStore();
    return useQuery(
        ["isAdmin", accessToken],
        () => {
            return APIClient.get<unknown, boolean>("users/authorization");
        },
        {
            refetchOnWindowFocus: false,
        },
    );
};
