import { Divider, Heading, VStack } from "@chakra-ui/react";
import { FunctionComponent } from "react";

import ReviewedRequestsList from "../ReviewedRequestsList";
import UnreviewedRequestsList from "../UnreviewedRequestsList";

interface RequestsListProps {}

const RequestsList: FunctionComponent<RequestsListProps> = () => {
    return (
        <VStack
            bg={"white"}
            borderRadius={"1rem"}
            p={"2rem"}
            w={"100%"}
            boxShadow={"0px 0px 8px 8px rgba(0, 0, 0, 0.01)"}
            alignItems={"stretch"}
        >
            <Heading
                fontSize={"1.75rem"}
                mb={"1rem"}
                fontWeight={500}
                color={"#155c01"}
            >
                New requests
            </Heading>
            <UnreviewedRequestsList />

            <Divider mt={"2rem"} mb={"2rem"} />

            <Heading
                fontSize={"1.75rem"}
                mb={"1rem"}
                fontWeight={500}
                color={"#3b3027"}
            >
                Completed requests:
            </Heading>
            <ReviewedRequestsList />
        </VStack>
    );
};

export default RequestsList;
