import { Box, ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { FunctionComponent } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Login from "./pages/Login";
import MainPage from "./pages/Main";
import ProtectedRoute from "./services/routing/ProtectedRoute";
import DefaultLayout from "./ui/layout/DefaultLayout";
import { theme } from "./ui/theme/mainTheme";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

interface AppProps {}

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <ProtectedRoute>
                <DefaultLayout>
                    <MainPage />
                </DefaultLayout>
            </ProtectedRoute>
        ),
    },
    {
        path: "/login",
        element: <Login />,
    },
]);

const queryClient = new QueryClient();

const App: FunctionComponent<AppProps> = () => {
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <ChakraProvider theme={theme}>
                    <RouterProvider router={router} />
                </ChakraProvider>
                <ReactQueryDevtools initialIsOpen={true} />
            </QueryClientProvider>
            <ToastContainer />
        </>
    );
};

export default App;
