import { Center, HStack, IconButton } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

interface PaginationProps {
    currentIndex: number;
    onChange(newIndex: number): void;
    totalPages?: number;
}

const Pagination: FunctionComponent<PaginationProps> = ({
    totalPages = 1,
    currentIndex,
    onChange,
}) => {
    return (
        <HStack gap={"0.5rem"} alignItems={"center"}>
            <IconButton
                cursor={"pointer"}
                borderRadius={"4px"}
                onClick={() => {
                    onChange(currentIndex - 1);
                }}
                bg={"transparent"}
                aria-label={""}
                isDisabled={currentIndex === 1}
            >
                <BsChevronLeft />
            </IconButton>

            {new Array(totalPages).fill(0).map((e, index) => {
                return (
                    <Center
                        key={index}
                        cursor={"pointer"}
                        minW={"1.25rem"}
                        borderBottom={
                            index + 1 === currentIndex
                                ? "1.5px solid black"
                                : "none"
                        }
                        onClick={() => {
                            onChange(index + 1);
                        }}
                    >
                        {index + 1}
                    </Center>
                );
            })}

            <IconButton
                cursor={"pointer"}
                borderRadius={"4px"}
                onClick={() => {
                    onChange(currentIndex + 1);
                }}
                bg={"transparent"}
                aria-label={""}
                isDisabled={currentIndex === totalPages}
            >
                <BsChevronRight />
            </IconButton>
        </HStack>
    );
};

export default Pagination;
